<template>
    <div class="page" v-loading="loading">
        <div style="margin-top: 30px;">
            <titleSelct @changeSel="changeSel"  @changeShowType="changeShowType" :showPopup="showPopup"></titleSelct>
            <div v-if="allTestPaperData.length === 0">
                <el-alert title="暂无数据" type="warning" center show-icon style="margin-top: 50px;">
                </el-alert>
            </div>
            <div v-else style="margin-top: -30px;">
                <allTestPaper :allTestPaperData="allTestPaperData" v-if="showType === 0"></allTestPaper>
                <allTestPaper2 :allTestPaperData="allTestPaperData" v-if="showType === 1"></allTestPaper2>
                <!-- 				<div v-if="haveMore" style="text-align: center;">
                                    <el-divider>加载中 <i class="el-icon-loading"></i></el-divider>
                                </div>
                                <div v-if="noMore" style="text-align: center;">
                                    <el-divider>我是有底线的。</el-divider>
                                </div> -->
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page="currentPage" :page-sizes="[9, 18, 27, 45, 54]" :page-size="pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                               style="margin-top: 10px;text-align: center">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import titleSelct from "@/components/titleSelect.vue"
    import allTestPaper from "./components/allTestPaper.vue"
    import allTestPaper2 from "./components/allTestPaper2.vue"
    import {
        getPaperList,
        getPaperByName
    } from "@/api/index.js"

    export default {
        components: {
            titleSelct,
            allTestPaper,
            allTestPaper2
        },
        data() {
            return {
                showPopup: 0,
                showType: 0,
                loading: false,
                allTestPaperData: [],
                allInfo: {
                    total: 0
                },
                nowType: {},
                currentPage: 1,
                pageSize: 9,
                haveMore: false,
                noMore: false,
                isLoading: true
            };
        },
        watch: {
            nowType: {
                handler: function (n, o) {
                    this.resetData();
                    this.loading = true
                    this.getTestPaper()
                }
            }
        },
        methods: {
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.currentPage = 1;
                this.getTestPaper()
                // this.
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getTestPaper()
            },
            changeSel(val) {
                this.nowType = val
            },
          changeShowType(val) {
            this.showType = val
          },
            resetData() {
                this.loading = false,
                    this.allTestPaperData = [];
                this.allInfo.total = 0;
                this.currentPage = 1;
                this.pageSize = 9;
                this.haveMore = false;
                this.noMore = false;
                this.isLoading = true;
            },
            getTestPaper(val) {
                let param = {
                    type: Number(this.nowType.type),
                    level: this.nowType.level,
                    name: this.nowType.name,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                }
                if (param.name === '' || param.name == undefined) {
                    getPaperList(param).then(res => {
                        if (res.code === "0000") {
                            this.allInfo = res.data
                            this.allTestPaperData = res.data.list
                            this.loading = false
                        }
                    })
                } else {
					console.log('试卷列表搜索')
                    getPaperByName(param).then(res => {
                        if (res.code === "0000") {
							this.allInfo = res.data
							this.allTestPaperData = res.data.list
							this.loading = false
                        }
                    }).catch(() => {
                        this.$router.push({
                            path: '/'
                        })
                    });
                }
            }
        },
        mounted() {
            let val = {
                type: 0,
                level: null,
                name: ''
            }
            this.nowType = val;
            this.getTestPaper()
            let _this = this;
            // window.onscroll = function() {
            // 	//变量scrollTop是滚动条滚动时，距离顶部的距离
            // 	var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // 	//变量windowHeight是可视区的高度
            // 	var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            // 	//变量scrollHeight是滚动条的总高度
            // 	var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            // 	//滚动条到底部的条件
            // 	if (scrollTop + windowHeight == scrollHeight) {
            // 		//到了这个就可以进行业务逻辑加载后台数据了
            // 		// _this.footerText = '我是有底线的';
            // 		if (_this.allInfo.total > _this.allTestPaperData.length) {
            // 			if (_this.isLoading) {
            // 				_this.isLoading = false
            // 				_this.haveMore = true
            // 				_this.currentPage = _this.currentPage + 1
            // 				setTimeout(() => {
            // 					console.log("2")
            // 					_this.getTestPaper(_this.nowType)
            // 				}, 1000);
            // 				console.log(_this.allTestPaperData)
            // 				console.log("到了底部");
            // 			}
            // 		} else {
            // 			_this.haveMore = false
            // 			_this.noMore = true
            // 		}
            // 	}
            // }
        }
    };
</script>

<style scoped>
    .el-divider__text {
        background-color: #f5f5f5;
        color: #808080;
    }
</style>
