<template>
  <div class="testPaperCenterSty" v-loading="loading">
    <div class="contentSty">
      <el-card class="box-card" shadow="hover" v-for="(item,index) in allTestPaperData" :key="item.id">
        <p class="title" @click="jumpToDetails(item.id)">{{ item.name }}</p>
        <p class="content" style="margin-top: 5px" @click="jumpToDetails(item.id)">
          学科：{{ item.examSubject.type | formatType }}</p>
        <p class="content" style="margin-top: 5px" @click="jumpToDetails(item.id)">试卷编号：NO.{{ item.id }}</p>
        <p class="content" style="margin-top: 5px" @click="jumpToDetails(item.id)">题目数量：{{ item.questionCount }}</p>
        <p class="content" style="margin-top: 5px" @click="jumpToDetails(item.id)">试卷总分：{{ item.score }}</p>
        <p class="content" style="margin-top: 5px" @click="jumpToDetails(item.id)">建议时长：{{
            item.suggestTime
          }}分钟</p>
        <p class="content" style="margin-top: 5px" @click="jumpToDetails(item.id)">发布时间：{{ item.createTime }}</p>
        <p class="content"><span>文件下载：</span>
          <el-button type="text" @click="downloadInit(item.id,item.name)">点击下载</el-button>
          <!--          <el-button type="text" @click="download()" @click="centerDialogVisible = true">点击下载</el-button>-->
        </p>


        <div class="tagGroup" style="margin-top: 15px" v-if="item.paperLabels">
          <!-- <el-tag class="tag" type="info" size="mini">{{item.paperLabels}}</el-tag> -->
          <el-tag class="tag" type="success" size="small" v-for="i in item.paperLabels.split(',')" :key="i">{{ i }}
          </el-tag>
          <el-tag class="tag" type="success" size="small" v-if="item.vipAnswer== 0">免费</el-tag>
          <el-tag class="tag" type="warning" size="small" v-if="item.vipAnswer== 1">VIP</el-tag>
          <el-tag class="tag" type="danger" size="small" v-if="item.vipAnswer== 2">VIP</el-tag>
          <!-- <el-tag class="tag" type="info" size="mini">{{item.examSubject.level | formatLevel}}</el-tag> -->
          <!--					<span><el-button type="primary" size="mini">下载<i class="el-icon-upload"></i></el-button></span>-->

        </div>


      </el-card>
    </div>

    <el-dialog
        title="选择下载的文件"
        :visible.sync="centerDialogVisible"
        width="25%"
        center>
      <div style="text-align: center">
        <el-radio v-model="radio" label="0">试卷-Word文件</el-radio>
        <el-radio v-model="radio" label="1">试卷-PDF文件</el-radio>
        <el-radio v-model="radio" label="2">答案解析</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="download()">确 定</el-button>
          </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="26%"
        center
        :before-close="handleClose">
      <h3 style="text-align: center;font-size:26px;">{{ content }}</h3>
      <span slot="footer" class="dialog-footer">
                <el-button type="danger" style="text-align: center;font-size:20px;" @click="toSelfVIP"
                           round>升级会员，继续练习</el-button>

				<div style="padding-bottom: 20px"></div>
<!--				<span style="color: red" v-show="dialogVisible2">注：开通SVIP，全站试卷均可作答</span>-->
			</span>

    </el-dialog>

  </div>
</template>

<script>
import {
  checkCountByAnswerPaperAndDay, paperDownCount,
  toDownload, toDownloadAnswer, selfInfo, toDownloadWord
} from '@/api/index.js'

export default {
  filters: {
    formatType(type) {
      switch (type) {
        case 0:
          return "Scratch"
          break;
        case 1:
          return "C/C++"
          break;
        case 2:
          return "Python"
          break;
        case 3:
          return "NOIP"
          break;
        case 4:
          return "机器人技术"
          break;
        case 5:
          return "科技素养/计算思维"
          break;
          // case 6:
          // 	return "无人机技术"
          // 	break;
        default:
          break;
      }
    },
    formatLevel(level) {
      switch (level) {
        case null:
          return "全部"
          break;
        case 0:
          return "模拟题"
          break;
        case 1:
          return "一级"
          break;
        case 2:
          return "二级"
          break;
        case 3:
          return "三级"
          break;
        case 4:
          return "四级"
          break;
        case 99:
          return "练习题"
          break;
        case 404:
          return "其他/四级以上"
          break;
        default:
          break;
      }
    }
  },
  props: {
    allTestPaperData: {
      type: Array,
      default: () => [{}]
    }
  },
  data() {
    return {
      radio: '0',
      paperId: 0,
      filename: '',
      centerDialogVisible: false,
      loading: false,
      fullscreenLoading: false,
      dialogVisible: false,
      dialogVisible2: false,
      flag: false,
      content: "此作品未开通权限，不可操作！"
    }
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
    },
    toSelfVIP() {
      this.$router.push({path: "/selfVIP"});
      this.dialogVisible = false;
    },
    jumpToDetails(id) {
      if (this.flag === false) {
        this.dialogVisible2 = false
        checkCountByAnswerPaperAndDay(id).then(res => {
          if (res.message === "此作品未开通权限，用户不可操作！") {
            this.content = "此作品未开通权限，不可操作！";
            this.dialogVisible = true
          } else if (res.message === "答题次数达到每日答题上限！可升级会员!") {
            this.content = "您好，今日答题次数已达到上限！";
            this.dialogVisible = true
          } else if (res.message === "此作品未开通权限，用户不可操作") {
            this.content = "此作品未开通权限，不可操作!";
            this.dialogVisible = true
            this.dialogVisible2 = true
          } else {
            if (res.code === "0000") {
              let routeData = this.$router.resolve({
                path: "/testPaperDetails?id=" + id
              });
              window.open(routeData.href, '_blank');
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          }
        })
      }

    },

    downloadInit(id, filename) {
      this.filename = filename
      this.paperId = id
      selfInfo().then(res => {
        if (res.code === "0000") {
          this.centerDialogVisible = true
        } else {
          this.centerDialogVisible = false
        }
      });
    },
    download() {
      this.centerDialogVisible = false

      if (this.radio === '0') {
        this.downloadPaperWord(this.paperId, this.filename)
      } else if (this.radio === '2') {
        this.downloadAnswer(this.paperId, this.filename)
      } else {
        this.downloadPaper(this.paperId, this.filename)
      }
    },

    downloadPaperWord(id, filename) {
      this.loading = true
      paperDownCount(id).then(res => {
        if (res.code === '0000') {
          this.$message({
            message: '开始下载...',
            type: 'success'
          });
          toDownloadWord(id, filename).then(res => {
            // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
            // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
            // var contentDisposition = decodeURI(res.headers['content-disposition'])
            // var result = patt.exec(contentDisposition)
            // var fileName = result[1]
            // fileName = fileName.replace(/\"/g, '')
            // console.log(fileName)
            //以下均为后台请求返回成功后对返回结果的处理，处理完成会从浏览器显示下载
            let blob = new Blob([res]);//response是返回的数据对象
            let downloadElement = document.createElement('a');
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.setAttribute('download', filename + ".doc")
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象

          }).catch(() => {
            this.$message({
              message: "您暂无文件下载权限 或 下载次数已达今日上限 ！",
              type: 'warning'
            });
          });

        } else {
          if (res.message === "此作品未开通权限，用户不可操作") {
            this.content = "此作品未开通权限，不可操作!";
            this.dialogVisible = true
            this.dialogVisible2 = true
          } else if (res.message === "您暂无文件下载权限 或 会员时间已到期 ！") {
            this.content = "您暂无文件下载权限 或 会员时间已到期！";
            this.dialogVisible = true
            this.dialogVisible2 = true
          } else if (res.message === "下载次数已达今日上限，明日再来哈!") {
            this.$message({
              message: '试卷下载次数已达今日上限，明日再来哈!',
              type: 'warning'
            });
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }

        }
        this.loading = false
      }).catch(() => {

        this.dialogVisible = true
        this.dialogVisible2 = true
        this.loading = false
      });
    },
    downloadPaper(id, filename) {

      this.loading = true
      paperDownCount(id).then(res => {
        if (res.code === '0000') {
          this.$message({
            message: '开始下载...',
            type: 'success'
          });
          toDownload(id, filename).then(res => {
            // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
            // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
            // var contentDisposition = decodeURI(res.headers['content-disposition'])
            // var result = patt.exec(contentDisposition)
            // var fileName = result[1]
            // fileName = fileName.replace(/\"/g, '')
            // console.log(fileName)
            //以下均为后台请求返回成功后对返回结果的处理，处理完成会从浏览器显示下载
            let blob = new Blob([res]);//response是返回的数据对象
            let downloadElement = document.createElement('a');
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.setAttribute('download', filename + ".pdf")
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象

          }).catch(() => {
            this.$message({
              message: "您暂无文件下载权限 或 下载次数已达今日上限 ！",
              type: 'warning'
            });
          });

        } else {
          if (res.message === "此作品未开通权限，用户不可操作") {
            this.content = "此作品未开通权限，不可操作!";
            this.dialogVisible = true
            this.dialogVisible2 = true
          } else if (res.message === "您暂无文件下载权限 或 会员时间已到期 ！") {
            this.content = "您暂无文件下载权限 或 会员时间已到期！";
            this.dialogVisible = true
            this.dialogVisible2 = true
          } else if (res.message === "下载次数已达今日上限，明日再来哈!") {
            this.$message({
              message: '试卷下载次数已达今日上限，明日再来哈!',
              type: 'warning'
            });
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }

        }
        this.loading = false
      }).catch(() => {

        this.dialogVisible = true
        this.dialogVisible2 = true
        this.loading = false
      });

    },

    downloadAnswer(id, filename) {
      this.$message({
        message: '开始下载',
        type: 'success'
      });
      this.loading = true
      toDownloadAnswer(id, filename).then(res => {
        // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
        // var contentDisposition = decodeURI(res.headers['content-disposition'])
        // var result = patt.exec(contentDisposition)
        // var fileName = result[1]
        // fileName = fileName.replace(/\"/g, '')
        // console.log(fileName)
        console.log(res)
        //以下均为后台请求返回成功后对返回结果的处理，处理完成会从浏览器显示下载
        let blob = new Blob([res]);//response是返回的数据对象
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.setAttribute('download', filename + "-参考答案.pdf")
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }).catch(err => {
        this.content = "您暂无文件下载权限 或 会员时间已到期！";
        this.dialogVisible = true
        this.dialogVisible2 = true
      });
      this.loading = false
    }
  }

}
</script>

<style lang="scss" scoped>


::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border-left: none
}

::v-deep.el-radio-button__inner {
  background: none;
  border: none
}

::v-deep.el-radio-button {
  border-radius: 5px
}

.testPaperCenterSty {
  width: 1280px;
  margin-top: 20px;
  min-height: 300px;
  background-color: #FFFFFF;
  position: relative;

  .contentSty {
    padding: 30px 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    color: #19d3ea;
    font-size: 18px;
    cursor: pointer; /*鼠标悬停变小手*/

    .box-card {
      width: 380px;
      text-align: left;
      margin-top: 10px;
      margin-left: 20px;

      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        height: 60px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .content {
        font-size: 14px;
        color: #808080;
      }

      .tagGroup {
        .tag {
          margin-left: 10px;
        }

        .tag:first-child {
          margin-left: 0px;
        }
      }
    }
  }
}
</style>
